// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
	.uiCard {
		width: fit-content;
		border-radius: 8px;

		&__title {
			font-size: 16pt;
		}

		&__description {
			font-size: 12pt;
			font-weight: 400;
		}
	}
}
