@font-face {
	font-family: 'BrownBold';
	src: local("BrownBold"), url('../fonts/Brown-Bold.woff2') format('woff2'),
	url('../fonts/Brown-Bold.woff') format('woff');
	font-weight: 600;
	font-display: optional;
}




@font-face {
	font-family: 'BrownLight';
	src: local("BrownLight"),
	url('../fonts/Brown-Light.woff2') format('woff2'),
	url('../fonts/Brown-Light.woff') format('woff');
	font-weight: 300;
	font-display: optional;
}




@font-face {
	font-family: 'BrownRegular';
	src: local("BrownRegular"), url('../fonts/Brown-Regular.woff2') format('woff2'),
	url('../fonts/Brown-Regular.woff') format('woff');
	font-weight: 400;
	font-display: optional;
}




@font-face {
	font-family: 'BrownThin';
	src: local("BrownThin"), url('../fonts/Brown-Thin.woff2') format('woff2'),
	url('../fonts/Brown-Thin.woff') format('woff');
	font-weight: 300;
	font-display: optional;
}

* {
	box-sizing: border-box;
}

p {
	font-size: 12pt;
}

html {
	overscroll-behavior-x: none;
	background-color: var(--light-background);
	width: 100%;
	height: 100%;
}

iframe {
	border: none;
}

body {
	overscroll-behavior-x: none;
	background-color: var(--light-background);
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	font-size: 12pt;
}


a {
	text-decoration: none;
	cursor: pointer;

	& > a,
	& > * > a {
		&:hover {
			text-decoration: underline;
		}
	}
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Standard syntax */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInFifty {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.5;
	}
}

/* Standard syntax */
@keyframes fadeInFifty {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.5;
	}
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInTwentyFive {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.25;
	}
}

/* Standard syntax */
@keyframes fadeInTwentyFive {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.25;
	}
}

#root {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	max-height: 100%;
	min-width: calc(max(100%, 400px));
	min-height: 100%;
	width: 100%;
	height: 100%;
	background-color: #121212;
	flex: 1;
}

label,
p,
th,
td,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6 strong,
input,
textarea,
div {
	margin: 0;
	color: var(--text-color);
	font-family: BrownRegular, BrownLight, BrownBold, sans-serif;
	line-height: normal;
}

div {
	display: flex;
	flex-direction: column;
}

input {
	border-radius: 8px;
	height: 40px;
	font-size: 16pt;
	font-weight: 400;
	outline: none;
	background-color: var(--white-4);
	color: var(--text-color);
	caret-color: var(--mint-primary);
	margin: 0;
	border: 1px solid transparent !important;
	font-family: BrownRegular, serif;
}

input:focus {
	border: 1px solid var(--mint-light) !important;
	cursor: text !important;
}

input:hover {
	border-color: var(--mint-lightest) !important;
	cursor: pointer;
}

input::-webkit-input-placeholder {
	font-weight: 400;
	color: var(--input-placeholder);
}

::-webkit-scrollbar {
	height: 0;
	width: 0;
}

/* Keep, Uses in many files */
::-webkit-scrollbar-thumb {
	display: none !important;
	height: 0;
	width: 0;
}

:root {
	--background-color: #101010;
	--light-background: #1b1b1b;
	--secondary-background-color: #171717;
	--extra-light-background-color: #212121;
	--text-color: rgba(255, 255, 255, 0.88);
	--input-placeholder: #a4a4a4;
	--light-grey: #949494;
	--dark-grey: #343434;
	--card-border: rgba(127, 127, 127, 0.03);
	--mint-primary: #33a188;
	--mint-lightest: #85d6c4;
	--mint-light: #54baa3;
	--mint-dark: #199579;
	--mint-darkest: #0a765e;
	--mint-primary-32: #33a18821;
	--red: #ff6666;
	--red-transparent: #ff6666aa;
	--red-transparent-half: #ff666655;
	--green: #9de187;
	--white-2: rgba(239, 239, 239, 0.02);
	--white-2-solid: #242424;
	--white-4: rgba(239, 239, 239, 0.04);
	--white-6: rgba(239, 239, 239, 0.06);
	--white-8: rgba(239, 239, 239, 0.08);
	--white-8-solid: #222222;
	--white-12-solid: #333333;
	--white-16-solid: #444444;
	--white-12: rgba(239, 239, 239, 0.12);
	--white-16: rgba(239, 239, 239, 0.16);
	--white-20: rgba(239, 239, 239, 0.2);
	--white-32: rgba(239, 239, 239, 0.32);
	--white-40: rgba(239, 239, 239, 0.4);
	--white-60: rgba(239, 239, 239, 0.6);
	--white-80: rgba(239, 239, 239, 0.8);
	--white-90: rgba(239, 239, 239, 0.9);
	--black-20: rgba(0, 0, 0, 0.2);
	--black-60: rgba(0, 0, 0, 0.6);
}
