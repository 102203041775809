// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.uiHeader {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	min-height: 48px;
	max-height: 48px;
	width: 100%;
	background-color: var(--secondary-background-color);
	border-bottom: var(--white-4) solid 1px;
	padding: 0 12px;

	&__loading {
		width: 32px;
		height: 32px;
		max-height: 32px;
	}

	&__right {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		padding: 6px;
		border-radius: 20px;
		background-color: var(--mint-light);


		&:hover {
			background-color: var(--mint-lightest);
		}

		&-text {
			margin-left: 6px;
			font-weight: 700;
			color: var(--dark-grey);
		}
	}

	&--login {
		font-weight: 600;
		color: var(--mint-primary);
		font-size: 10pt;
		margin: 0 8px 0 0;
		cursor: pointer;
	}

	&__left {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__center {
		&--left {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			min-width: 32px;
			width: 32px;

			&--icon {
				object-fit: contain;
				height: 28px;
				width: 28px;
				min-height: 28px;
				min-width: 24px;
				max-height: 28px;
				max-width: 28px;
				opacity: 0.8;
				//border-radius: 14px;
				margin-right: 12px;
			}

			&--link {
				margin: 0 12px;
				font-weight: 300;
				color: rgba(255, 255, 255, 0.75);
				text-shadow: 0 0 1px transparent, 0 0 1px transparent;
				transition: text-shadow 0.3s;
				white-space: nowrap;

				&-selected {
					color: white;
					//font-weight: 500;
					text-shadow: 0 0 1px white, 0 0 1px white;
				}
			}
		}

		&--title {
			color: white;
			font-weight: 600;
			font-size: 13pt;
			white-space: nowrap;
			cursor: pointer;
		}

		&--right {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex: 0 1 fit-content;

			&--cm-item {
				padding: 12px 32px;
				text-align: center;
				font-weight: 800;
				font-size: 14pt;
				margin: 8px 0;
			}
		}
	}
}

.headerImage {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	border-radius: 50%;
	transition: 250ms all ease-out;

	&__iconContainer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__notificationBtn {
		//background-color: var(--white-4);
		//border: var(--white-8) solid 1px;
		color: white;
		height: 32px;
		border-radius: 16px;
		padding: 4px 8px;
		margin: 0;
		transition: 0.15s all;

		&:hover {
			cursor: pointer;
			//background-color: var(--white-8);
			transform: scale(0.96);
		}
	}

	&--img {
		width: 20px;
		height: 20px;
		object-fit: cover;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			transform: scale(0.98);
		}
	}
}

.headerContainer {
	height: 64px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;

	&__left {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: fit-content;
		cursor: pointer;

		&--container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;

			&-icon {
				height: 36px;
				margin-left: 8px;
			}
		}

		&--item {
			height: 48px;
			width: 40px;
			margin: 0;
			padding: 10px;
			border-radius: 4px;
		}

		&--logo {
			height: 36px;
			margin-left: 8px;
		}
	}
}

.header-mobile-link-selected {
	font-size: 12pt;
	width: 100%;
	background-color: var(--light-background);
	/*border-top: var(--white-8) solid 1px;*/
	margin: 0;
	padding: 12px 16px;
	cursor: pointer;
	font-weight: 500;
	outline: none;
}

.header-mobile-link-unselected a {
	font-size: 12pt;
	width: 100%;
	background-color: var(--light-background);
	/*border-top: var(--white-8) solid 1px;*/
	margin: 0;
	padding: 0;
	cursor: pointer;
	font-weight: 400;
	outline: none;
	text-decoration: none;
}

.header-mobile-link-unselected {
	font-size: 12pt;
	width: 100%;
	background-color: var(--light-background);
	/*border-top: var(--white-8) solid 1px;*/
	margin: 0;
	padding: 12px 16px;
	cursor: pointer;
	font-weight: 400;
	outline: none;
	text-decoration: none;
}

.headerMobileIcon {
	height: 24px;
	width: 24px;
	margin-left: 8px;
	object-fit: contain;
}

.activeTab {
	border-bottom: 2px solid var(--mint-primary) !important;

	&:hover {
		border-bottom: 2px solid var(--mint-light) !important;
	}
}

.rightButtonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	&__tabs {
		height: 100%;
		display: flex;

		&--1 {
			width: fit-content;
			border-bottom: 2px solid transparent;
			max-height: 100%;
			display: flex;
			border-radius: 2px;
			text-align: center;
			font-weight: 600;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: 8px 16px;
			transition-duration: 0.15s;
			user-select: none;
			font-size: 10pt;
			transition: 200ms all;
			color: white;
			margin: 0 8px;

			&:hover {
				background-color: var(--mint-light);
				color: black;
			}
		}
	}

	&--book {
		background-color: var(--mint-primary);
		border: var(--white-8) solid 1px;
		color: black;
		border-radius: 16px;
		padding: 4px 8px;
		margin: 0 8px;
		transition: 0.15s all;

		&:hover {
			cursor: pointer;
			transform: translateY(-2px);
		}
	}

	&--login {
		background-color: var(--white-4);
		border: var(--white-8) solid 1px;
		color: white;
		border-radius: 16px;
		padding: 4px 8px;
		margin: 0 8px;
		transition: 0.15s all;

		&:hover {
			cursor: pointer;
			background-color: var(--white-8);
			transform: translateY(-2px);
		}
	}

	&--photographers {
		cursor: pointer;
	}
}

.defaults {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
}

.page-header {
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1;
	width: 100%;
	height: fit-content;
	min-height: 64px;
	margin: 0;
	background-color: var(--light-background);
}

.headerMenu {
	width: 32px;
	height: 32px;
	padding: 6px;
	border-radius: 4px;

	&:hover {
		background-color: var(--white-4);
	}
}

.bm-item-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	outline: none;
}

.pad-16 {
	padding: 16px;
}

.profile {
	cursor: pointer;
	padding-left: 16px;
	padding-right: 16px;
	height: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;

	&__img {
		min-width: 40px;
		margin-right: 16px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	&__name {
		margin: 0;
		font-weight: 500;
		font-size: 11pt;
	}

	&__email {
		margin: 0;
		font-weight: 300;
		font-size: 9pt;
	}
}

.loginPanel {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__login {
		margin-top: 16px;
		color: white;
		width: fit-content;
		border-radius: 17.5px;
		height: 35px;
		line-height: 35px;
		padding: 0 12px;
		background-color: var(--mint-primary);
	}

	&__signup {
		border-radius: 17.5px;
		margin-top: 16px;
		width: fit-content;
		height: 35px;
		line-height: 35px;
		padding: 0 12px;
		background-color: var(--white-8);
	}
}

.external {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	height: 64px;
	min-height: 64px;
	max-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

// history btn
.history__btn {
	position: absolute;
	right: 0;
	background-color: var(--white-4);
	border: var(--white-8) solid 1px;
	color: white;
	height: 32px;
	border-radius: 16px;
	padding: 4px 8px;
	margin: 0 16px;
	transition: 0.15s all;
}

.headerContextMenu {
	top: 16px !important;
	right: 16px !important;
	left: unset !important;
}

.profileContextMenu {
	display: flex;
	margin: 0;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&__menuItem {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 4px 8px 4px 4px;
		cursor: pointer;
		transition: all 0.2s ease-out;
		border-radius: 2px;

		&:hover {
			cursor: pointer;
			background-color: var(--white-8);
		}

		&--icon {
			margin: 0;
			height: 32px;
			width: 32px;
			padding: 4px;
			object-fit: contain;
			transition: 150ms all ease-out;
		}

		&--text {
			margin: 8px 0 8px 12px;
			white-space: nowrap;
			font-size: 14pt;
			font-weight: 700;
		}
	}
}
