// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
	.uiHeader {
		min-height: 48px;
		max-height: 48px;
		padding: 0 12px;
		&__right {
			padding: 6px 12px;
		}

		&__center {
			&--left {
				min-width: 88px;
				width: 88px;
			}
		}
	}
	.profileContextMenu {
		display: flex;
		margin: 0;
	}
}
