// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.sortPhotos {
	display: flex;
	flex-direction: column;
	position: fixed;
	background-color: var(--secondary-background-color);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 6;

	&--image {
		animation: fadeIn 100ms ease-in-out;
		-webkit-animation: fadeIn 100ms ease-in-out;
		-moz-animation: fadeIn 100ms ease-in-out;
		-o-animation: fadeIn 100ms ease-in-out;
	}

	&__container {
		display: flex;
		width: 100%;
		padding: 0 8px;
		flex-direction: row;
		margin: 0 12px 16px 12px;

		& > * {
			margin: 0 8px;

			&:first-of-type {
				margin: 0 8px 0 0;
			}
		}
	}

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 64px;
		margin: 0 0 8px 0;

		&--title {
			font-weight: 600;
		}

		&--action {
			color: var(--light-grey);
			font-weight: 500;
			margin: 2px 0 0 0;
		}

		&--close {
			padding: 20px;
			height: 64px;
			width: 64px;
			transition: 200ms all ease-in-out;
			cursor: pointer;
			margin: 0 8px 0 0;

			&:hover {
				transform: scale(0.98);
				background-color: var(--white-16);
			}
		}
	}
}
