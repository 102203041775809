// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************

.uiCard {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	background-color: var(--white-4);
	border: var(--white-8) solid 1px;
	flex: 0 1 auto;
	max-width: 100%;
	padding: 16px;
	transition: 0.2s all;
	height: fit-content;
	min-height: fit-content;

	&__top {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: fit-content;
		align-items: center;

		&--icon {
			width: 28px;
			height: 28px;
			transition: all 300ms ease-out;
			margin-right: 12px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__title {
		padding: 0;
		font-weight: 600;
		font-size: 13pt;
		margin-bottom: 4px;
		width: 100%;

		&Alt {
			padding: 16px 0 0 0;
			font-weight: 600;
			font-size: 13pt;
			margin-bottom: 4px;
			width: 100%;
		}
	}

	&__description {
		color: var(--white-80);
		font-size: 12pt;
		font-weight: 500;
		margin-top: 12px;
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		& > * {
			margin: 4px !important;
		}
	}
}
