// MOBILE STYLES BELOW *****************************************************************************************
.agentTeamMembersModal__add {
	margin-top: 24px;
}

.agentTeamMembersModal__delete {
	margin-top: 16px;

	&--button {
		margin-right: 8px;
	}
}
