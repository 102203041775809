// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************

.fadeInOutTransition {
	&-appear {
		//background-color: rgba(255, 255, 255, 0);

		&-active {
			background-color: rgba(255, 255, 255, 0) !important;
		}

		&-done {
			//background-color: rgba(255, 255, 255, 0.6);
		}
	}

	&-enter {
		&-active {
			background-color: rgba(255, 255, 255, 0.6) !important;
		}

		&-done {
			background-color: rgba(255, 255, 255, 0.6) !important;
			transition: background-color 600ms;
		}
	}

	&-exit {
		background-color: rgba(255, 255, 255, 0.6) !important;

		&-active {
			background-color: rgba(255, 255, 255, 0.6) !important;
			transition: background-color 600ms;
		}

		&-done {
			background-color: rgba(255, 255, 255, 0) !important;
			transition: background-color 600ms;
		}
	}
}
