






.pl-separator {
	background-color: var(--white-8);
	height: 1px;
	min-height: 1px;
	margin: 24px 0;
	width: 100%;

	&:first-of-type {
		margin: 24px 0 !important;
	}
}



.rightPanel {
	&__content {
		display: flex;
		flex-direction: column;
		width: 400px;
		margin-top: 64px;
		height: calc(100% - 64px);
		border-left: var(--white-8) solid 1px;
		background-color: var(--background-color);
		transition: all 0.2s;
	}
}

.general {
	&-header-pin {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		border-bottom: var(--white-4) solid 1px;
		align-items: center;
		padding: 12px;
		flex: 0 1 fit-content;
		width: 100%;
		min-height: fit-content;
	}
}
