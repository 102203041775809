// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
	.uiCard {
		width: fit-content;
		border-radius: 8px;

		&__title {
			font-size: 14pt;
		}
	}
}
