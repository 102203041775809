// MOBILE STYLES BELOW *****************************************************************************************
.tableEditingComp {
	flex: 1 1 auto;
	overflow-y: auto;
	margin: 0;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	max-width: 100%;

	&__rows {
		display: flex;
		flex-direction: column;
		overflow: auto;
		padding: 12px;
		flex: 1;
	}

	&__row {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		transition-duration: 0.2s;
		background-color: var(--white-4);
		border-radius: 4px;
		margin-bottom: 12px;
		padding: 12px;
		flex: 0 0 auto;
		border: var(--white-2) solid 1px;

		&:hover {
			background-color: #5eac9b22;
			cursor: pointer;
		}

		&:last-of-type {
			margin: 0;
		}

		&--item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex: 1 1 100%;
			font-size: 10pt;
			color: var(--text-color);
			padding: 4px 8px;
			margin: 0;
			height: 100%;
			min-height: 60px;
			white-space: nowrap;
			text-transform: lowercase;

			&--name {
				font-size: 14pt;
				font-weight: 600;
			}
		}
	}
}
