.errorBody {
	height: 100%;
	padding: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 5s;
	animation-name: logo-error;
	animation-iteration-count: 1;
	animation-duration: 1s;
	flex: 1;

	&--card {
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		background-color: var(--white-4);
		border: var(--white-8) solid 1px;
		flex: 0 1 auto;
		max-width: 100%;
		padding: 36px;
		transition: 0.2s all;
		height: fit-content;
		min-height: fit-content;

		&-title {
			font-size: 24pt;
		}

		&-help {
			font-size: 18pt;
			color: var(--text-color);
		}

		&-description {
			margin-top: 12px;
			font-size: 14pt;
			color: var(--light-grey)
		}
	}

	&__logo {
		height: 64px;
		width: fit-content;
		object-fit: contain;
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		margin: 16px 0 0 0;
	}

	&__chat {
		margin-top: 24px;

		&--text {
			padding-top: 8px;
		}

		&-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			padding: 12px 18px;
			width: fit-content;
			border-radius: 24px;
			background-color: var(--mint-light);
			margin-top: 12px;


			&:hover {
				background-color: var(--mint-lightest);
			}

			&-text {
				margin-left: 6px;
				font-weight: 700;
				font-size: 14pt;
				color: var(--dark-grey);
			}
		}
	}
}

#root > div > div > div.errorBody__buttons > div.errorBtns > p {
	margin-right: 16px;
}

@keyframes logo-error {
	0% {
		transform: translateY(-500px);
	}
	100% {
		transform: translateY(0px);
	}
}
