@font-face {
	font-family: 'BrownBold';
	src: local("BrownBold"), url('../../fonts/Brown-Bold.woff2') format('woff2'),
	url('../../fonts/Brown-Bold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}




@font-face {
	font-family: 'BrownLight';
	src: local("BrownLight"), url('../../fonts/Brown-Light.woff2') format('woff2'),
	url('../../fonts/Brown-Light.woff') format('woff');
	font-weight: 300;
	font-display: swap;
}




@font-face {
	font-family: 'BrownRegular';
	src: local("BrownRegular"), url('../../fonts/Brown-Regular.woff2') format('woff2'),
	url('../../fonts/Brown-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}




@font-face {
	font-family: 'BrownThin';
	src: local("BrownThin"), local("Brown-Thin"), url('../../fonts/Brown-Thin.woff2') format('woff2'),
	url('../../fonts/Brown-Thin.woff') format('woff');
	font-weight: 200;
	font-display: swap;
}

.tippy {
	&-popper {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	&-tooltip {
		max-width: calc(min(440px, 100%));

		&-content {
			background-color: #f1f1f1;
			border-radius: 6px;
			border: var(--white-8) solid 1px;
			font-weight: 500;
			padding: 18px;
			max-width: calc(min(440px, 100%));
			color: var(--dark-grey);
		}
	}
}

.react-contextmenu {
	background-color: var(--white-12-solid);
	padding: 8px;
	z-index: 5;
	border-radius: 8px;
	border: var(--white-2) solid 1px;
}

.plContextMenu {
	background-color: var(--white-12-solid);
	padding: 8px;
	z-index: 5;
	border-radius: 8px;
	border: var(--white-2) solid 1px;

	&--list {
		border-radius: 2px;
		font-size: 11pt;
		cursor: pointer;
		width: 100%;
	}

	&--item {
		padding: 8px !important;
		margin: 4px 0 !important;
		border-radius: 4px !important;
		font-size: 11pt;
		cursor: pointer;
		text-align: center;

		&:hover {
			background-color: var(--white-8);
		}
	}
}

.cursor {
	cursor: pointer;
}


/* Keep, Uses in many files */
.no-scrollbar::-webkit-scrollbar,
.no-scrollbar div::-webkit-scrollbar {
	height: 0;
	width: 0;
}

/* Keep, Uses in many files */
.no-scrollbar::-webkit-scrollbar-thumb {
	display: none !important;
	height: 0;
	width: 0;
}

/* width */

.black-text {
	font-weight: 600;
}

hr {
	margin: 8px 0;
}
h2 {
	color: white !important;
}

.light-text {
	font-weight: 200;
}

.fsp-summary__item {
	min-height: fit-content;
}

.fade-in-div {
	-webkit-animation: fadein 250ms ease-out;
	-moz-animation: fadein 250ms ease-out;
	-o-animation: fadein 250ms ease-out;
	animation: fadein 250ms ease-out;

	& > * {
		& > * {
			-webkit-animation: fadein 250ms ease-out;
			-moz-animation: fadein 250ms ease-out;
			-o-animation: fadein 250ms ease-out;
			animation: fadein 250ms ease-out;
		}

		-webkit-animation: fadein 250ms ease-out;
		-moz-animation: fadein 250ms ease-out;
		-o-animation: fadein 250ms ease-out;
		animation: fadein 250ms ease-out;

		& > * {
			& > * {
				-webkit-animation: fadein 250ms ease-out;
				-moz-animation: fadein 250ms ease-out;
				-o-animation: fadein 250ms ease-out;
				animation: fadein 250ms ease-out;
			}

			-webkit-animation: fadein 250ms ease-out;
			-moz-animation: fadein 250ms ease-out;
			-o-animation: fadein 250ms ease-out;
			animation: fadein 250ms ease-out;

			& > * {
				& > * {
					-webkit-animation: fadein 250ms ease-out;
					-moz-animation: fadein 250ms ease-out;
					-o-animation: fadein 250ms ease-out;
					animation: fadein 250ms ease-out;
				}

				-webkit-animation: fadein 250ms ease-out;
				-moz-animation: fadein 250ms ease-out;
				-o-animation: fadein 250ms ease-out;
				animation: fadein 250ms ease-out;
			}
		}
	}
}

.general-table-row:hover {
	background-color: #22222211;
	cursor: pointer;
}

.page-title {
	font-weight: 600;
	font-size: 14pt;
	margin: 0;
}

.general-sub-text {
	font-weight: 300;
	font-size: 12pt;
	margin: 0;
}

.plModal-zIndex-fix {
	z-index: 11 !important;
}

.mapboxgl-popup-content {
	background-color: var(--background-color) !important;
	border-radius: 32px;
}

.mapboxgl-popup-tip {
	border-top-color: var(--background-color) !important;
}

.player-wrapper {
	border-radius: 8px;
	overflow: hidden;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.pineapple-fab {
	position: fixed;
	bottom: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 14px;
	width: 64px;
	height: 64px;
	background-color: var(--mint-primary);
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: top 0.4s, bottom 0.4s;
	transition: top 0.4s, bottom 0.4s;
}

.pineapple-fab:hover:active {
	opacity: 0.7;
}

.add-fab-image {
	width: 32px;
	height: 32px;
}

.Toastify__toast {
	flex-direction: row;
	border-radius: 4px !important;

	&-container {
		top: 64px;
	}

	&-body {
		white-space: pre-wrap;
		font-weight: 600;
		font-size: 10pt;
		color: white;
	}
}

.Toastify__progress-bar--dark {
	background: var(--mint-primary) !important;
	border: var(--white-8) solid 1px;
}

.Toastify__progress-bar--success {
	background: var(--mint-primary) !important;
	border: var(--white-8) solid 1px;
}

.Toastify__progress-bar--error {
	background: var(--red) !important;
	border: var(--white-8) solid 1px;
}

.Toastify__toast--success {
	background: var(--extra-light-background-color) !important;
	border: var(--white-8) solid 1px;
}

.Toastify__toast--error {
	background: var(--extra-light-background-color) !important;
	border: var(--white-8) solid 1px;
}

.Toastify__toast--info {
	background: var(--extra-light-background-color) !important;
	border: var(--white-8) solid 1px;
}

.mapboxgl-ctrl-bottom-right {
	display: none;
}

//Overlay global css
.general-title {
	font-weight: 600;
	font-size: 12pt;
	margin: 0;
	width: 100%;
}

.general-description {
	color: var(--white-80);
	font-size: 10pt;
	font-weight: 500;
}

.general-overlay-sub-text {
	margin: 4px;
	color: var(--light-grey);
	font-size: 10pt;
	min-height: 32px;
}

.general-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 24px 0 0 0;

	& > * {
		margin: 6px 12px 6px 0 !important;
	}
}
.general-buttons-container-no-margin {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	& > * {
		margin: 6px 12px 6px 0 !important;
	}
}

.generalHeader {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-bottom: var(--white-8) solid 1px;
	align-items: center;
	padding: 8px;
	&--add {
		background-color: var(--mint-primary);
		border-radius: 16px;
		height: 32px;
		width: 32px;
		min-width: 32px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-right: 8px;

		&-plus {
			transition: all 0.2s;
			height: 20px;
			width: 20px;
			padding: 2px;

			&:hover {
				transform: rotate(90deg);
			}
		}
	}
}

@media (min-width: 660px) and (max-width: 1024px) {
	.general-title {
		font-weight: 600;
		font-size: 14pt;
		margin: 0;
	}
	.general-description {
		margin: 8px 0 0 0;
		color: var(--white-80);
		font-size: 10pt;
		font-weight: 500;
	}
}

@media only screen and (min-width: 1025px) {
	.general-title {
		font-weight: 600;
		font-size: 16pt;
		margin: 0;
	}
	.general-description {
		margin: 8px 0 0 0;
		color: var(--white-80);
		font-size: 11pt;
		font-weight: 500;
	}
}
