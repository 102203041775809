// MOBILE STYLES BELOW *****************************************************************************************
.tableAgent {
	max-width: 100%;
	height: fit-content;
	table-layout: fixed;
	overflow: auto;
	width: 100%;
	padding: 0;
	margin: 0;
	border-radius: 2px;
	display: flex;
	flex-direction: column;

	&__rows {
		overflow: auto;
		flex: 1 1 auto;
		width: 100%;
		padding: 12px;
	}
}

.agentRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	transition-duration: 0.2s;
	flex: 0 0 auto;
	min-height: 74px;
	border: var(--white-2) solid 1px;
	border-radius: 4px;
	background-color: var(--white-4);
	margin-bottom: 12px;
	padding: 12px 0;
	width: 100%;
	animation: fadeIn 100ms ease-in-out;
	-webkit-animation: fadeIn 100ms ease-in-out;
	-moz-animation: fadeIn 100ms ease-in-out;
	-o-animation: fadeIn 100ms ease-in-out;

	&:last-of-type {
		margin: 0;
	}

	&:hover {
		background-color: #5eac9b22;
		cursor: pointer;
	}

	&__image {
		border-radius: 24px !important;
		overflow: hidden;
		border: var(--white-8) solid 1px;
		width: 48px;
		height: 48px;
		object-fit: cover;
		margin: 0 8px;
	}

	&__name {
		text-align: start;
		width: 100%;
		flex: 1 1 auto;
		font-size: 13pt;
		font-weight: 600;
		margin: 0 12px;
		color: white;
	}

	&__email {
		text-align: start;
		font-size: 11pt;
		margin: 0 12px;
		flex: 0 1 auto;
		white-space: nowrap;
		color: var(--light-grey);
		width: 100%;
	}
}
