// MOBILE STYLES BELOW ****************************************************************************************

.pl-drp-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.pl-drp-input-container {
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	justify-content: flex-start;
}

.pl-drp-input-delete-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
}

.pl-drp-input-label {
	text-transform: uppercase;
	font-size: 9pt;
	font-weight: 300;
	margin: 0 0 4px 0;
}

.pl-drp-input-date {
	padding: 0 16px;
	width: 240px;
}

// REACT_DATES STYLES BELOW ****************************************************************************************
.DayPickerKeyboardShortcuts_show__bottomRight {
	display: none !important;
}

.DayPickerNavigation {
	&_svg {
		&__horizontal {
			fill: white !important;
		}
	}

	&_button {
		&__default {
			background-color: var(--white-8) !important;
			border: var(--white-12) !important;

			&:hover {
				background-color: var(--white-8) !important;
				border: none !important;
				transform: translateY(-1px) !important;
			}
		}
	}
}

.DayPicker {
	background-color: var(--background-color) !important;
	border: var(--white-40) solid 1px !important;
	border-radius: 8px !important;
	padding: 12px 0 0 0 !important;
	overflow: hidden;
	margin: 0;

	&__withBorder {
		box-shadow: none !important;
	}
}

.CalendarDay {
	background-color: var(--background-color) !important;
	border: none !important;

	&__blocked_calendar {
		color: rgba(255, 255, 255, 0.45) !important;
		cursor: not-allowed !important;
		background: repeating-linear-gradient(-45deg, transparent, var(--white-16) 2px, black 3px) !important;
	}

	&__default {
		color: var(--text-color) !important;
	}

	&__hovered {
		background-color: var(--white-4) !important;

		&_span {
			background-color: var(--white-4) !important;
			color: var(--text-color) !important;
			font-weight: 300 !important;

			&:hover {
				color: var(--text-color) !important;
				font-weight: 500 !important;
			}
		}
	}

	&__selected {
		color: var(--mint-primary) !important;
		background-color: var(--white-16) !important;

		&_span {
			background-color: var(--white-8) !important;
			color: var(--text-color) !important;
			font-weight: 300 !important;

			&:hover {
				color: var(--text-color) !important;
			}
		}
	}

	&:hover {
		background-color: var(--white-8) !important;
		//border-radius: 4px !important;
		border: none !important;
	}
}

.CalendarMonthGrid {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--background-color) !important;
	width: 100% !important;
	height: fit-content !important;

	&__horizontal {
		left: 0 !important;
	}

	&_month {
		width: fit-content !important;
		height: fit-content !important;

		&__horizontal {
			min-height: unset !important;
			left: 16px !important;
		}
	}
}

.CalendarMonth {
	padding: 0 !important;
	background-color: var(--background-color) !important;

	&_caption {
		color: var(--text-color) !important;
		margin-bottom: 12px;
	}
}

.DateInput {
	background: none !important;
	width: fit-content !important;
	height: 48px;

	&_fangStroke {
		stroke: var(--background-color) !important;
		fill: var(--background-color) !important;
	}

	&_input {
		background-color: var(--light-background) !important;
		border: 2px solid var(--white-20) !important;
		border-radius: 8px !important;
		color: white !important;
		height: 48px;
		font-weight: 500 !important;
	}
}

.SingleDatePicker {
	width: 128px;

	&Input {
		width: fit-content !important;
		background-color: unset !important;
		border: none !important;
		height: 48px;
	}

	&_picker {
		background-color: transparent !important;
	}
}

// DESKTOP STYLES BELOW ****************************************************************************************

@media only screen and (min-width: 768px) {
	.pl-drp-container {
		flex-direction: row;
	}

	.pl-drp-input-container {
		margin-right: 16px;
	}

	.pl-drp-input-label {
	}

	.pl-drp-input-date {
	}
}
