.additionalInfo {
	&--total {
		margin: 24px 0 0 0;
		font-weight: 600;
		font-size: 16pt;
		color: var(--mint-primary);
	}

	&--text-input {
		min-width: 100%;
		margin-top: 24px;
	}
}
