.uiMaintenancePage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&--heading {
			text-align: center;
			padding-bottom: 16px;
		}

		&--text {
			text-align: center;
		}
	}

	&__img {
		width: 64px;
		margin-top: -88px;
		border-radius: 8px;
		padding: 12px;
		animation: logo-shine 4s;
		animation-iteration-count: infinite;
	}

	&__spin {
		border: 2px solid #0042a4;
		border-radius: 50%;
		border-top: 2px solid var(--mint-primary);
		border-right: 2px solid transparent;
		border-bottom: 2px solid transparent;
		border-left: 2px solid transparent;
		width: 100px;
		height: 100px;
		-webkit-animation: spin 4s linear infinite;
		animation: spin 4s linear infinite;
	}

	&__logos {
		display: flex;
		flex-direction: row;
		padding: 64px;

		&--male {
			position: relative;
			animation: logo-plugin 4s;
			animation-iteration-count: infinite;
			z-index: 2;
		}

		&--female {
			position: relative;
			animation: logo-plugin-female 4s;
			animation-iteration-count: infinite;
			z-index: 3;
		}
	}
}

@keyframes logo-plugin {
	0% {
		transform: translateX(-20px);
	}
	50% {
		transform: translateX(26px);
	}
	100% {
		transform: translateX(-20px);
	}
}

@keyframes logo-plugin-female {
	0% {
		transform: translateX(46px);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(46px);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		background-color: var(--white-8);
	}
	100% {
		transform: rotate(360deg);
	}
}
