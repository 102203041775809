.plHover {
	display: flex !important;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	max-width: 100%;
	max-height: 100%;

	&__popover {
		background-color: var(--light-background);
		-webkit-user-select: none;
		padding: 16px;
		border: var(--white-8) solid 1px;
		border-radius: 4px;

		&--text {
			margin: 0;
			font-size: 10pt;
		}
	}
}
