.regionRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 4px;
	padding: 4px 8px;
	border-radius: 4px;
	width: fit-content;
	cursor: pointer;

	&--name {
		font-weight: 300;
		font-size: 14pt;
		margin: 0;
	}

	&:hover {
		background-color: var(--red-transparent);
	}
}
