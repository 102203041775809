// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.uiVideo {
	background: var(--white-8);
	width: fit-content;
	max-width: 100%;
	margin: 0;
	border-radius: 8px;
	overflow: hidden;

	&__fullscreen {
		width: 100% !important;
		border-radius: 8px;
	}

	&__content {
		display: flex;
		justify-content: center;
		padding: 8px;
		margin-bottom: 12px;

		&-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			& > :first-child {
				margin: 0 8px 0 0;
			}
		}
	}

	&--filename {
		margin: 8px 0 16px 0;
		font-weight: 600;
	}

	&__hide {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 4px;
		width: fit-content;
		padding: 8px;
		cursor: pointer;

		&:hover {
			background-color: var(--white-8);
		}

		&-label {
			margin: 0 4px 0 0;
		}
	}

	&--player {
		width: 100% !important;
		height: fit-content !important;
		max-width: 100%;
		max-height: 100%;
	}
}
