// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************

.slideInOutTransition {
	&--up-down {
		&-appear {
			&-active {
			}

			&-done {
				opacity: 1 !important;
			}
		}

		&-enter {
			&-active {
				opacity: 1 !important;
			}

			&-done {
				opacity: 1 !important;
			}
		}

		&-exit {
			&-active {
				transform: translateY(100vh) !important;
			}

			&-done {
				transform: translateY(100vh) !important;
			}
		}
	}

	&--right-left {
		&-appear {
			transform: translateX(-100vw) !important;

			&-active {
			}

			&-done {
				opacity: 1 !important;
			}
		}

		&-enter {
			&-active {
				transform: translateX(-100vw) !important;
				opacity: 1 !important;
			}

			&-done {
				transform: translateX(0) !important;
				opacity: 1 !important;
			}
		}

		&-exit {
			&-active {
				transform: translateX(-100vw) !important;
			}

			&-done {
				transform: translateX(-100vw) !important;
			}
		}
	}
	&--left-right {
		width: fit-content;
		right: 0;
		position: absolute;

		&-appear {
			&-active {
				right: -520px !important;
			}

			&-done {}
		}

		&-enter {

			&-active {
				right: 0 !important;
			}

			&-done {
				right: 0 !important;
				transition: right 600ms;
			}
		}

		&-exit {
			right: 0;

			&-active {
				right: -520px !important;
				transition: right 600ms;
			}

			&-done {
				right: -520px !important;
			}
		}
	}
}
