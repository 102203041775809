// MOBILE STYLES BELOW *****************************************************************************************
.tableAvailableEditor {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&__options {
		width: fit-content;

		&--header {
			font-size: 12pt;
			font-weight: 600;
			margin-bottom: 8px;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		&--row {
			border: var(--white-2) solid 1px;
			display: flex;
			background-color: var(--white-4);
			border-radius: 4px;
			margin: 4px 8px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			-webkit-transition-duration: 0.2s;
			transition-duration: 0.2s;
			padding: 12px 8px;
			flex: 0 0 auto;

			&:first-of-type {
				margin: 8px 8px 4px 8px;
			}

			&:hover {
				background-color: #5eac9b22;
				cursor: pointer;
			}

			& > * {
				margin: 0 8px;
			}
		}

		&--datetime {
			width: 128px;
			min-width: 108px;
			display: flex;
			flex-direction: column;
			margin: 0 0 0 12px;

			&-date {
				margin: 6px 0 0 0;
				color: var(--white-80);
				font-weight: 600;
				font-size: 11pt;
			}

			&-time {
				margin: 0;
				font-weight: 500;
				font-size: 10pt;
				color: var(--light-grey);
			}
		}

		&--address {
			min-width: 256px;
			flex: 1 1;
			width: fit-content;
			margin: 0 0 0 8px;
			text-align: start;
			font-size: 12pt;

			font-weight: 700;
			color: white;
		}

		&__options {
			display: flex;
			flex-direction: column;
			width: 256px;

			& > * {
				margin: 2px;
			}
		}
	}
}
