// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
	.tableEditingComp {
		&__rows {
			width: 100%;
		}

		&__row {
			flex-direction: row;
			align-items: center;
		}
	}
}
