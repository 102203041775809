// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {


	::-webkit-scrollbar {
		margin-top: -2px;
		width: 8px;
		height: 8px;
		position: absolute;

		::after {
			content: '' !important;
			position: absolute !important;
			background-color: white !important;
			//bottom: -10px;
			bottom: 0;
			left: 10px !important;
			width: 100% !important;
			height: 2px !important;
		}
	}
	::-webkit-scrollbar-corner {
		background-color: var(--light-background);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		display: unset !important;
		background: var(--white-20);
		border-radius: 6px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.rightPanel {
		&__background {
			flex: 1 1 100%;
		}
	}
}
