// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.uiCertifiedUserSearch {
	display: flex;
	flex-direction: column;

	&__text {
		padding: 12px;
		font-size: 11pt;
		font-weight: 500;
		color: var(--light-grey);
	}

	&__selected-description {
		margin-bottom: 12px;
		font-weight: 500;
	}

	&__content {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		align-items: flex-start;
		width: 100%;
		border-radius: 8px;
	}

	&__parameters {
		margin: 24px 6px;
		&-text {
			color: var(--light-grey)
		}

		&-value {
			color: white;
			font-weight: 500;
		}
	}
}
