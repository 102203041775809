// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************

.tableEditorsContainer {
	display: flex;
	flex-direction: column;
	max-width: 100% !important;
	height: auto;
	table-layout: fixed;
	overflow: auto;
	width: 100%;
	padding: 0;
	margin: 0;
	border-radius: 2px;

	&__header {
		display: flex;
		max-width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		transition-duration: 0.2s;
		background-color: var(--secondary-background-color);
		padding: 16px 0;

		&--label {
			margin: 0;
			font-weight: 500;
			color: var(--text-color);
			flex: 1 1 100%;
			font-size: 12pt !important;
			white-space: nowrap;
			text-transform: lowercase;
			padding: 0 8px;
			max-width: 33%;
		}
	}

	&__row {
		border-bottom: var(--white-8) solid 1px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		transition-duration: 0.2s;
		padding: 6px 0;
		flex: 0 0 auto;

		&:last-of-type {
			margin: 0;
		}

		&:hover {
			background-color: #5eac9b22;
			cursor: pointer;
		}

		&--item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			max-width: 33%;
			padding: 4px 8px;
			margin: 0;
			height: 100%;
			min-height: 60px;
			flex: 1 1 100%;
			font-size: 10pt;
			color: var(--text-color);
			text-transform: lowercase;

			&-text {
				flex: 1 1 100%;
				margin: 0;
			}
		}
	}
}

.tableEditors {
	width: 100%;
}

.tableEditorsMobile {
	width: calc(100% - 16px);
	padding: 16px;
	border: var(--card-border) solid 1px;
	border-radius: 8px;
	background-color: var(--white-8);
	margin: 8px;

	&__name {
		font-size: 14pt;
		font-weight: 600;
		margin: 0;
		color: var(--text-color);
	}

	&__email {
		font-size: 12pt;
		margin: 0;
		color: var(--text-color);
	}

	&__certs {
		margin: 0;
		color: var(--text-color);
		text-align: start;
		font-weight: 500;
		font-size: 10.5pt;

		&--heading {
			font-weight: 300;
			font-size: 9.5pt;
			padding: 8px 0 4px;
		}
	}
}
