// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
	.uiVideo {
		&--player {
			width: 100%;
			height: 100%;
			max-width: 640px;
			max-height: 640px;
		}
	}
}
