// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.photographerListLabel {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;

	&__img {
		border-radius: 50%;
		border: var(--white-8) solid 1px;
		width: 48px;
		height: 48px;
		object-fit: cover;
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&--name {
			text-align: start;
			width: 100%;
			flex: 1 1 auto;
			font-size: 13pt;
			font-weight: 600;
			margin: 0 12px 6px 12px;
			text-transform: capitalize;
			color: white;
		}

		&--email {
			text-align: start;
			font-size: 11pt;
			margin: 0 12px;
			flex: 0 1 auto;
			white-space: nowrap;
			color: var(--light-grey);
			width: 100%;
		}
	}
}

.tableTech {
	max-width: 100% !important;
	flex: 1 1 auto;
	overflow-y: auto;
	width: 100%;
	margin: 0;
	border-radius: 2px;
	display: flex;
	flex-direction: column;

	&__rows {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding: 12px;
	}

	&__row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		transition-duration: 0.2s;
		flex: 0 0 auto;
		border: var(--white-2) solid 1px;
		background-color: var(--white-4);
		border-radius: 4px;
		margin-bottom: 12px;
		padding: 6px 0;
		animation: fadeIn 100ms ease-in-out;
		-webkit-animation: fadeIn 100ms ease-in-out;
		-moz-animation: fadeIn 100ms ease-in-out;
		-o-animation: fadeIn 100ms ease-in-out;

		&:last-of-type {
			margin: 0;
		}

		&:hover {
			background-color: #5eac9b22;
			cursor: pointer;
		}

		&--item {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1 1 100%;
			font-size: 10pt;
			padding: 4px 8px;
			margin: 0;
			height: 100%;
			min-height: 60px;
			color: var(--text-color);
			text-transform: lowercase;
		}
	}
}
