.uiTeamMember {
	max-width: 100%;
	height: fit-content;
	border: var(--card-border) solid 1px;
	background-color: var(--white-6);
	border-radius: 8px;
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 8px 8px 8px 0;
	padding: 8px;
	cursor: pointer;
	transition: all 200ms ease-out;

	&:hover {
		transform: translateY(1px);
	}

	&-uncertified {
		opacity: 0.4;
		display: flex;
		width: fit-content;
		flex-direction: row;
		align-items: center;
		padding: 8px;
		margin: 0 0 8px 0;
		cursor: not-allowed;
		background-color: var(--white-8);
		border: var(--white-4) solid 1px;
		border-radius: 8px;
	}

	&-selected {
		width: fit-content;
		height: fit-content;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 8px 8px 8px 0;
		padding: 8px;
		cursor: pointer;
		background-color: var(--secondary-background-color);
		border: solid var(--light-grey) 1px;

		&:hover {
			background-color: var(--red-transparent) !important;
			border: solid var(--red-transparent) 1px;
		}
	}

	&__info {
		padding: 12px;
		display: flex;
		flex-direction: column;

		&-name {
			font-size: 14pt;
			font-weight: 600;
			margin: 0;
			color: white;
		}

		&-phone {
			margin: 0;
			font-size: 12pt;
			font-weight: 500;
			color: var(--light-grey);
		}

		&-email {
			font-size: 12pt;
			margin: 6px 0 0 0;
		}
	}

	&--image {
		width: 52px;
		height: 52px;
		min-width: 52px;
		min-height: 52px;
		max-width: 52px;
		max-height: 52px;
		border-radius: 50%;
	}
}
