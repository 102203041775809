// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
	.uiVideo {
		&--player {
			width: 100%;
			height: 100% !important;
			max-width: 640px;
			max-height: 640px;
		}
	}
}
