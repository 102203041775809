.eds-card {
	display: flex;
	max-width: 100%;
	padding: 12px;
	height: fit-content;
	border: var(--card-border) solid 1px;
	background-color: var(--white-6);
	border-radius: 8px;
	flex-direction: column;

	&--text {
		font-weight: 500;
		color: var(--text-color);
		font-size: 14pt;
		margin: 16px;
		text-align: center;
	}
}
