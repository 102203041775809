// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {

	//::-webkit-scrollbar {
	//	width: 8px;
	//	height: 8px;
	//}
	::-webkit-scrollbar-corner {
		background-color: var(--light-background);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--white-20);
		border-radius: 6px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.rightPanel {

		&__background {
			flex: 1 1 100%;
		}
	}
}
