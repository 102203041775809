.plServiceAdminRibbons {
	padding: 0 12px;
	&__ribbon {
		margin: 0 0 12px 0 !important;
		max-width: 100% !important;
		height: 64px !important;
		&-disabled {
			cursor: default !important;
			opacity: 1 !important;
			transform: translateY(0) !important;
		}
		&-green {
			border: var(--mint-primary) solid 1px;
		}
		&-red {
			border: var(--red-transparent) solid 1px;
		}
	}
}
