// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.optionTable {
	display: flex;
	flex-direction: column;
	padding: 12px;
	overflow-y: auto;

	&__rows {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__container {
		max-width: 100%;
		width: 100%;
		padding: 12px;
		flex: 1 1 fit-content;
		min-height: fit-content;
		border: var(--card-border) solid 1px;
		background-color: var(--white-6);
		flex-direction: column;
		border-radius: 4px;
		margin-bottom: 12px;

		&--name {
			font-weight: 600;
			font-size: 12pt;
		}

		&--price {
			font-size: 12pt;
			color: var(--mint-primary);
		}

		&--pay {
			font-size: 10pt;
			font-weight: 400;
		}

		&--type {
			font-size: 10pt;
			font-weight: 400;
		}
	}
}

.tableOption {
	display: flex;
	max-width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	transition-duration: 0.2s;
	background-color: var(--secondary-background-color);
	padding: 16px 0;

	&--rows {
		max-width: 100%;
		overflow-y: auto;
		height: 100%;
		padding: 12px;

		&:last-child {
			border-bottom: none;
		}
	}

	&__row {
		background-color: var(--white-4);
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		transition-duration: 0.2s;
		flex: 0 0 auto;
		margin-bottom: 12px;
		padding: 12px 0;
		border-radius: 4px;
		animation: fadeIn 100ms ease-in-out;
		-webkit-animation: fadeIn 100ms ease-in-out;
		-moz-animation: fadeIn 100ms ease-in-out;
		-o-animation: fadeIn 100ms ease-in-out;

		&:last-of-type {
			margin: 0;
		}

		&:hover {
			background-color: #5eac9b22;
			cursor: pointer;
		}

		&--item {
			padding: 8px;
			margin: 0;
			height: 100%;
			min-height: 60px;
			flex: 1 1 100%;
			font-size: 10pt;
			color: var(--text-color);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			white-space: nowrap;

			&--cover {
				max-width: 96px;
				max-height: 57px;
				object-fit: contain;
				border-radius: 4px;
				margin: 0 0 0 8px;

				&-empty {
					display: flex;
					justify-content: center;
					align-items: center;
					min-width: 90px;
					height: 57px;
					border-radius: 4px;
					margin: 0 0 0 8px;
					background-color: var(--white-4);

					&--text {
						color: var(--white-60);
						font-size: 10pt;
						font-weight: 500;
					}
				}
			}

			&--name {
				font-size: 14pt;
				font-weight: 600;
				color: white;
				margin: 0 0 0 8px;
			}

			&--price {
				font-size: 12pt;
				color: var(--mint-primary);
				margin: 0 0 0 8px;
			}

			&--package-type {
				font-size: 14pt;
				color: white;
				margin: 0 12px 0 0;
			}
		}
	}
}
