//LAPTOP
@media only screen and (min-width: 1025px) {
  .tableListingRow {
    flex-direction: row;
    align-items: center;

    &__info {
      align-items: flex-end;
    }

    &__client {
      justify-content: flex-end;
    }
  }
}
