// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
	.uiHeader {
		min-height: 48px;
		max-height: 48px;
		&__right {
			padding: 6px 12px;
		}
	}
	.profileContextMenu {
		display: flex;
	}
}
