// MOBILE STYLES BELOW *****************************************************************************************
.declineOverlayButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 0;

  & > * {
    margin: 4px;
  }

  &:first-child {
    margin: 0;
  }
}

.tableAP {
  &__options {
    margin: 0 0 0 4px;
    font-size: 12pt;
    font-weight: 500;
    width: 152px;

    &--text {
      padding-bottom: 4px;
    }
  }

  &__datetime {
    width: 128px;
    min-width: 108px;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 12px;

    &--date {
      margin: 6px 0 0 0;
      color: var(--white-80);
      font-weight: 600;
      font-size: 11pt;
    }

    &--time {
      margin: 0;
      font-weight: 500;
      font-size: 10pt;
      color: var(--light-grey);
    }
  }

  &__accept {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 40px;
    margin: 0 8px 0 0;
    height: 40px;
    min-height: 40px;
    border-radius: 4px;
    background-color: var(--mint-primary);
    cursor: pointer;

    &:hover {
      background-color: var(--mint-dark);

      & > p {
        color: white;
      }
    }
  }

  &--notFlex {
    color: var(--light-grey);
    font-size: 12pt;
    font-weight: 500;
    padding: 0 4px;
    margin-bottom: 12px;
  }

  &__suggest {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    height: 40px;
    min-height: 40px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: var(--white-40);

      & > p {
        color: white;
      }
    }
  }

  &__request {
    width: fit-content;
    padding: 0 24px;
  }

  &__pay {
    min-width: 88px;
    width: fit-content;
    margin: 0 0 0 8px;

    &--text {
      margin: 0 4px;
      font-weight: 600;
      font-size: 12pt;
      color: var(--mint-primary);
    }
  }

  &__pending {
    font-weight: 800;
    border: var(--white-32) solid 2px;
    border-radius: 6px;
    padding: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &__client {
    flex-direction: row;
    align-items: center;
    width: 240px;

    &-image {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      margin-right: 12px;
    }
  }

  &__address {
    min-width: 256px;
    flex: 1 1;
    width: fit-content;
    margin: 0 0 0 24px;
    text-align: start;
    font-weight: 400;
    font-size: 12pt;

    &--text {
      margin: 0 4px;
      font-weight: 700;
      font-size: 12pt;
    }

    &--alias {
      color: var(--light-grey);
      margin: 0 4px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    & > * {
      margin: 4px;
    }
  }

  &__card {
    margin: 0 0 8px 0;
    display: flex;
    width: 100%;
    height: fit-content;
    border: var(--card-border) solid 1px;
    background-color: var(--white-6);
    border-radius: 4px;
    flex-direction: column;
    padding: 16px;
    min-height: fit-content;
    cursor: pointer;
    transition: 300ms all ease-out;

    &:hover {
      background-color: var(--mint-primary-32);
    }
  }

  &--request {
    margin: 8px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-size: 14pt;
    color: var(--mint-primary) !important;
    font-weight: 600;

    &-mobile {
      margin: 0 0 24px 0;
      font-size: 14pt;
      font-weight: 600;
      color: white;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }

  &__heading {
    margin: 12px 0 0 0;
    font-size: 12pt;
    color: var(--light-grey);
  }

  &__addressMobile {
    font-weight: 600;
    font-size: 14pt;
    color: white;
  }

  &__text {
    text-align: start;
    font-weight: 500;
    font-size: 12pt;
    white-space: pre-wrap;
    margin-top: 6px;
  }
}

.moreInfoModal {
  &__options {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;

    &--heading {
      font-size: 12pt;
      font-weight: 600;
      color: var(--light-grey);
    }

    &--list {
      font-size: 10pt;
      font-weight: 400;
      margin: 0;
      width: 100%;
      flex: 1 1 100%;
      color: var(--text-color);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      word-wrap: break-word;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0 0 0;
    align-items: center;

    &:first-child {
      margin-right: 16px;
    }
  }
}
