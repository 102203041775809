// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.date-picker-container {
	margin: 0;
}

.rc-time-picker {
	& * {
		display: block;
		background-color: var(--light-background) !important;
	}

	&-input {
		border-color: var(--white-8) !important;
		min-height: 48px;
		font-size: 14pt !important;
		color: var(--text-color) !important;
	}

	&-panel {
		&-inner {
			box-shadow: none !important;
			border-color: var(--white-8) !important;
			font-size: 16px !important;
			width: 280px !important;
			border-radius: 8px !important;
			overflow: hidden;
			background-color: var(--light-background) !important;
		}

		& * {
			display: block;
			background-color: var(--light-background) !important;
		}
	}
}

.rc-time-picker-panel-select {
	overflow-x: hidden !important;
	z-index: 10 !important;
	height: 150px !important;

	ul {
		//height: 155px !important;
	}
}

.rc-time-picker-panel-select {
	width: 33% !important;
	//height: auto !important;
	//max-height: initial !important;
	//overflow-y: scroll !important;
}

.rc-time-picker-panel-input {
	border-color: transparent !important;

	&:hover {
		border-color: transparent !important;
	}
}

.rc-time-picker-panel-select li {
	font-size: 14px !important;
	height: 35px !important;
	line-height: 35px !important;
}

.rc-time-picker-panel-select li {
	color: var(--text-color) !important;
	outline: none !important;
}

.rc-time-picker-panel-inner {
	border-width: 2px !important;
	border-color: var(--white-16-solid) !important;
}

li.rc-time-picker-panel-select-option-disabled {
	color: var(--dark-grey) !important;
}

li.rc-time-picker-panel-select-option-selected {
	background-color: var(--white-8) !important;
	color: var(--mint-primary) !important;
	border-radius: 4px !important;
}

.rc-time-picker-panel-select li:hover {
	background-color: var(--white-8) !important;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
	position: relative;
	width: 80vw;
	margin: 0;
	cursor: pointer;
}

.rdt input {
	cursor: pointer;
	color: var(--text-color);
	border: none;
	background-color: var(--background-color);
	font-size: 12pt;
	font-weight: 300;
	font-family: BrownRegular, serif;
	caret-color: transparent !important;
}

.rdtPicker {
	display: none;
	background-color: var(--light-background);
	position: absolute;
	width: 100%;
	padding: 4px;
	margin: 16px 0 0 0;
	border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
	display: block;
}

.rdtStatic .rdtPicker {
	position: static;
	border-radius: 4px;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}

.rdtPicker td,
.rdtPicker th {
	text-align: center;
}

.rdtPicker td {
	cursor: pointer;
	font-size: 11pt;
	user-select: none;
	padding: 4px;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}

.rdtPicker td.rdtToday {
	position: relative;
}

.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid var(--mint-primary);
	border-top-color: var(--mint-primary);
	position: absolute;
	bottom: 4px;
	right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: var(--mint-primary);
	border-radius: 4px;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: var(--light-grey);
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: var(--light-grey);
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
	color: var(--light-grey);
	font-size: 10pt;
	font-weight: 500;
}

.rdtPicker td {
	font-weight: 500 !important;
}

.rdtPicker th.rdtSwitch {
	width: auto;
	font-weight: 500;
	font-size: 12pt;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 24px;
	vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	font-size: 12pt;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
	cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}

.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.rdtCounter .rdtBtn:hover {
	background: #eee;
}

.rdtCounter .rdtCount {
	font-size: 24px;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 24px;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}
