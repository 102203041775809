// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.approveServiceCheckboxItem {
	display: flex;
	flex-direction: column;
	margin: 24px 0 0 0;
	padding: 0 24px 0 0;

	&:first-of-type {
		margin: 0;
	}

	&__top {
		display: flex;
		flex-direction: row;
		align-items: center;

		&--title {
			margin-left: 12px;
			height: fit-content;
		}
	}

	&__button {
		margin-top: 12px;
	}

	&__content {
		display: flex;
		flex-direction: column;

		&--subtext {
			margin: 12px 0 0 64px;
			font-weight: 300;
		}

		&--button {
			margin: 16px 0 0 64px;
		}
	}

	&__text {
		margin: 0 0 8px 0;
	}

	&__optionList {
		display: flex;
		flex-direction: row;
		padding: 4px;
		margin: 4px 4px 4px 0;
		height: fit-content;
		border-radius: 4px;
		justify-content: flex-start;
		align-items: center;

		&--name {
			font-size: 12pt;
			font-weight: 600;
			margin: 8px;
		}
	}
}
