/*Fill Type*/
.pineapple-button-fill-disabled {
	background-color: var(--dark-grey);
	color: white;
	cursor: pointer;
	text-align: center;
	height: 30px;
	width: fit-content;
	padding: 2px 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 11pt;
	font-weight: 500;
	white-space: nowrap;
	border-radius: 15px;
	user-select: none;
	margin: 0;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.pineapple-button-fill {
	background-color: var(--mint-primary);
	color: var(--background-color);
	cursor: pointer;
	text-align: center;
	width: fit-content;
	height: 30px;
	padding: 2px 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 11pt;
	font-weight: 500;
	border-radius: 15px;
	user-select: none;
	margin: 0;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.pineapple-button-fill:hover {
	background-color: var(--mint-light);
	color: var(--secondary-background-color);
}

.pineapple-button-fill:active:hover,
.pineapple-button-fill:active {
	background-color: var(--mint-lightest);
}

/*Outline Type*/
.pineapple-button-outline-disabled {
	background-color: var(--dark-grey);
	color: white;
	cursor: pointer;
	text-align: center;
	width: fit-content;
	height: 30px;
	padding: 4px 16px;
	white-space: nowrap;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 11pt;
	font-weight: 500;
	border-radius: 15px;
	user-select: none;
	margin: 0;
}

.pineapple-button-outline {
	color: var(--mint-primary);
	cursor: pointer;
	text-align: center;
	width: fit-content;
	height: 30px;
	max-height: 40px;
	padding: 4px 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 11pt;
	font-weight: 500;
	border-radius: 15px;
	white-space: nowrap;
	user-select: none;
	margin: 0;
	border: 1px solid var(--mint-primary);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.pineapple-button-outline:active:hover,
.pineapple-button-outline:active {
	background-color: var(--mint-lightest);
	border: 1px solid var(--mint-light);
}

.pineapple-button-outline:hover {
	color: white;
	border-color: var(--mint-lightest);
	background-color: var(--mint-light);
}

/*Cancel Type*/
.pineapple-button-cancel-disabled {
	background-color: var(--light-grey);
	color: white;
	cursor: pointer;
	text-align: center;
	height: 30px;
	width: fit-content;
	max-width: 650px;
	display: block;
	white-space: nowrap;
	font-size: 11px;
	font-weight: 500;
	border-radius: 15px;
	user-select: none;
	margin: 0;
}

.pineapple-button-cancel {
	color: white;
	background-color: var(--red);
	cursor: pointer;
	text-align: center;
	width: fit-content;
	height: 30px;
	max-height: 40px;
	padding: 4px 16px;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 11pt;
	font-weight: 500;
	border-radius: 15px;
	user-select: none;
	margin: 0;
	border: 1px solid var(--red-transparent);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.pineapple-button-cancel:hover {
	background-color: var(--red-transparent);
	color: white;
}

.pineapple-button-cancel:hover:active {
	color: white;
}
