// MOBILE STYLES BELOW *****************************************************************************************

.plModal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	min-height: 100%;
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: center;
	z-index: 11;
	transition: all 100ms ease-out;
	animation: fadeIn 100ms ease-in-out;
	-webkit-animation: fadeIn 100ms ease-in-out;
	-moz-animation: fadeIn 100ms ease-in-out;
	-o-animation: fadeIn 100ms ease-in-out;

	&--blur {
		background-color: rgba(200, 200, 200, 0.6);
	}

	&__body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	&--close {
		cursor: pointer;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 16px;
		left: 16px;
	}
}

// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
	.plModal {
		padding: 32px;

		&__body {
			justify-content: center;
			align-items: center;
		}
	}
}

// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
	.plModal {
		align-items: center;
		padding: 24px;

		&__body {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		&--close {
			width: 32px;
			height: 32px;
			transform: scale(1) rotate(0deg);
			transition: 0.2s all;

			&:hover {
				transform: scale(0.9) rotate(90deg);
				transition: 0.2s all;
			}
		}
	}
}

.fadeOut {
	animation: fadeOut 300ms;
	transition: all 300ms ease-out;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
