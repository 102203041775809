.autocomplete {
	&-container-book {
		top: 100%;
		position: relative;
		height: auto;
		list-style-type: none;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;
		padding-inline-end: 0;
		width: 100%;
		overflow: hidden;
		border-radius: 8px;
		//border: var(--white-4) solid 1px;
		margin: 8px 0;
	}
}

.geosuggest {
	width: 100%;
	height: 40px;
	z-index: 3;
	display: block;

	&__item {
		background-color: var(--white-8);

		& > span {
			font-size: 13pt;
		}

		&__matched-text {
			color: white;
			font-weight: 700;
		}
	}

	&__suggests {
		background-color: var(--white-2-solid);
		display: block;
		border-radius: 6px;

		&--hidden {
			max-height: 0;
			overflow: hidden;
			border-width: 0;
		}

		&-wrapper {
			z-index: 3;
			display: block;
		}
	}
}

.autocompleteItem-book {
	padding: 10px;
	color: var(--text-color);
	font-size: 11pt;
	cursor: pointer;
	font-weight: 500;
	width: 100%;
}

.autocompleteItem-book:hover {
	color: var(--dark-grey) !important;
	background-color: var(--mint-primary);

	& > span > .geosuggest__item__matched-text {
		color: var(--dark-grey);
	}
}

.autocomplete-root-book {
	position: relative;
	padding-bottom: 0;
	max-width: 600px;
	width: 100%;
}

.autocomplete-root-book input {
	width: 100%;
	font-size: 12pt;
	padding: 0 8px;
	border: var(--white-8) solid 1px;
	background-color: var(--white-4);
}
