.uiCheckbox {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.checkbox-check-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	min-width: 32px;
	border: 1px solid var(--white-20);
	border-radius: 4px;
	background-color: #000000aa;
	transition: 200ms all ease-out;

	&:hover {
		background-color: #000000;
		border: 1px solid var(--white-40);
		cursor: pointer;
	}

	&--sub {
		height: 24px;
		min-height: 24px;
		width: 24px;
		min-width: 24px;
		border-radius: 4px;
	}
}

.checkbox-check-container-selected {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
	border: 1px solid var(--mint-primary);
	border-radius: 4px;
	padding: 6px;
	user-select: none;
	transition: 200ms all ease-out;

	&:hover {
		background-color: var(--white-20);
	}

	&--sub {
		height: 24px;
		min-height: 24px;
		width: 24px;
		min-width: 24px;
		border-radius: 4px;
		padding: 2px;
		border: 2px solid var(--mint-primary);
	}
}

.checkbox-label {
	margin: 0 6px 0 16px;
	font-weight: 300;
	font-size: 12pt;
	color: var(--text-color);
}

.checkbox-label-selected {
	margin: 0 6px 0 16px;
	font-weight: 500;
	font-size: 12pt;
}
