// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.tableAffiliates {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-width: 100% !important;
	flex: 1 1 auto;
	width: 100%;
	margin: 0;
	border-radius: 2px;

	&__rows {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding: 12px;
		flex: 1 1 auto;
	}

	&__row {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition-duration: 0.2s;
		flex: 0 0 auto;
		border: var(--white-2) solid 1px;
		justify-content: flex-start;
		background-color: var(--white-4);
		border-radius: 4px;
		margin-bottom: 12px;
		padding: 12px 0;

		&:last-of-type {
			margin: 0;
		}

		&:hover {
			background-color: #5eac9b22;
			cursor: pointer;
		}

		&--item {
			display: flex;
			flex-direction: row;
			padding: 4px 8px;
			margin: 0;
			height: 100%;
			min-height: 60px;
			flex: 1 1 100%;
			font-size: 10pt;
			color: var(--text-color);
			align-items: center;
			justify-content: flex-start;
			white-space: nowrap;
			text-transform: lowercase;
		}
	}
}

.salesman {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;

	&--image {
		border-radius: 50%;
		border: var(--white-8) solid 1px;
		width: 48px;
		height: 48px;
		object-fit: cover;
	}

	&--name {
		text-align: start;
		font-size: 11pt;
		width: 100%;
		margin-left: 16px;
		font-weight: 600;
		color: var(--mint-primary);
	}
}
