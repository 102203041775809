// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.uiUserSearch {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 1 fit-content;
		width: 100%;
		border-radius: 8px;
		margin-top: 12px;
	}

	&__text {
		padding: 12px;
		font-size: 11pt;
		font-weight: 500;
		color: var(--light-grey);
	}
}
