// MOBILE STYLES BELOW *****************************************************************************************
.serviceNotes {
	&--notes {
		white-space: pre-wrap;
		border-radius: 12px;
		border: var(--white-6) solid 1px;
		padding: 12px;
		margin: 12px 0 0 0;
		background-color: var(--white-6);
		color: var(--white-80);
		font-size: 13pt;
		font-weight: 400;
	}
}
.notesMobileContainer {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 100%;
	min-height: 100px;
	animation: Panel 0.4s;
	word-wrap: break-word;

	&--icon {
		width: 24px;
		height: 24px;
		opacity: 0.8;
		transition: all 0.2s;
		&:first-child {
			margin-right: 6px;
		}
		&:hover {
			cursor: pointer;
			transition: all 0.2s;
			transform: scale(0.9);
		}
	}

	&__admin {
		width: 100%;
		font-weight: 300;

		&--notes {
			margin: 8px 0;
			font-weight: 600;
			width: 100%;
			padding-bottom: 8px;
			border-bottom: 1px solid white;
		}
	}

	&__title {
		font-size: 16pt;
		font-weight: 600;
		padding: 16px 16px 0 16px;
	}

	&__userNotes {
		max-width: 100%;
		padding: 12px;
		height: fit-content;
		border: var(--card-border) solid 1px;
		background-color: var(--white-6);
		border-radius: 8px;
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin: 16px;
	}

	&__allNotes {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		&--section {
			max-width: 100%;
			width: 100%;
			padding: 16px;
			flex: 1 1 100%;
			height: fit-content;
			border: var(--card-border) solid 1px;
			background-color: var(--white-6);
			border-radius: 8px;
			flex-direction: column;
			margin: 8px 0;

			&__header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin: 0 0 16px 0;

				&--title {
					font-weight: 600;
					font-size: 14pt;
					text-transform: lowercase;
					color: var(--white-80);

					&-container {
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 16px;
						width: 32px;
						height: 32px;
						background-color: var(--white-4);
						transition: 200ms all ease-out;

						&:hover {
							cursor: pointer;
							transition: all 0.2s;
							transform: scale(0.9);
						}
					}
				}

				&--icon {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
			}

			&-notes {
				white-space: pre-line;
			}
		}
	}
}

@keyframes Panel {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
