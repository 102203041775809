// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.tableUserSearch {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: fixed;
	max-width: 600px;
	background-color: var(--white-8-solid);
	border-radius: 2px 2px 8px 8px;
	padding: 12px;
	border: var(--white-16) solid 1px;
	border-right: var(--white-16) solid 1px;

	&--rows {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		flex: 1 1 auto;
		min-height: fit-content;
	}
}

.overlayLabel {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	margin: 12px 0 0 0;

	&:first-of-type {
		margin: 0;
	}

	&__container {
		max-width: 100%;
		padding: 12px;
		height: fit-content;
		min-height: fit-content;
		border: var(--card-border) solid 1px;
		background-color: var(--white-16-solid);
		border-radius: 8px;
		width: 100%;
		display: flex;
		margin-right: 12px;
		flex-direction: column;

		&:hover {
			cursor: pointer;
		}
	}
}
