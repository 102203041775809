// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
	.tableEditingComp {
		&__rows {
			width: 100%;
		}

		&__row {
			flex-direction: row;
			align-items: center;
		}
	}
}
