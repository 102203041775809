.uploadcare {
	&--tab {
		&_remote {
			background-color: white;
		}
	}
	&--preview {
		&__back {
			display: none;
		}
	}
	&--widget {
		display: none;
		&__status {
			&_loading {
				display: none;
			}
		}
	}

	&--footer {
		&__button {
			max-width: 50% !important;
		}
	}

	&--file {
		padding-right: 12px;
		&:hover {
			background-color: var(--mint-primary-32);
		}
		&__name {
			font-weight: 500;
		}
		&__size {
			margin-left: 6px;
		}
		&__description {
			flex-direction: row;
			align-items: center;
			margin-right: 12px;
		}
		&__remove {
			background-color: var(--red-transparent) !important;
			&:hover {
				background-color: var(--red-transparent-half) !important;
			}
		}
		&_status {
			&_uploading,
			&__preview {
				color: var(--mint-primary) !important;
				border-color: transparent !important;
			}
		}
		&__preview {
			color: var(--mint-primary) !important;
			background: transparent !important;
			border-color: transparent !important;
		}
	}

	&--progressbar {
		margin-right: 24px !important;
		&__value {
			background: var(--mint-primary);
		}
	}
	&--icon {
		color: var(--secondary-background-color);
	}
	&--progress_type_canvas {
		color: var(--mint-primary) !important;
		height: fit-content;
	}
	&--menu {
		&__toggle {
			display: none !important;
		}
		&__item {
			background-color: transparent;
			outline-color: white;
			cursor: pointer;
			border-radius: 6px;

			&:hover {
				background-color: var(--white-40);
			}

			&_current {
				background-color: var(--white-12);
			}
		}
		&__items {
			background: unset;
		}
		&__icon {
			color: white;
		}
	}
	&--panel {
		background-color: var(--secondary-background-color);

		&__footer {
			background-color: var(--white-12);
			border-radius: 6px;
			margin: 12px;
			width: unset;
			display: none;
		}

		&__file {
			&-counter {
				font-weight: 600;
				color: var(--secondary-background-color);
			}
		}
		&__show {
			&-files {
				display: none;
			}
		}
	}

	&--dialog {
		&__close {
			top: 8px;
			right: 8px;
			background-color: var(--white-20) !important;
			& > * {
				color: var(--white-60);
			}
		}
	}
	&--button {
		background-color: var(--mint-primary);
		border-color: var(--mint-primary);
		cursor: pointer;
		outline: none !important;
		color: var(--secondary-background-color);

		&_primary {
			color: var(--secondary-background-color);
			&[aria-disabled='true'] {
				color: var(--dark-grey) !important;
			}
		}

		&:hover {
			color: var(--secondary-background-color);
			background-color: var(--mint-light);
			border-color: var(--mint-light);
		}

		&:focus {
			color: var(--secondary-background-color);
			background-color: var(--mint-light);
			border-color: var(--mint-light);
		}

		&_muted {
			//display: none !important;
			color: var(--secondary-background-color);
			border-radius: 6px;
			width: 40px;
			height: 40px;
			padding: 4px;
			align-self: center;

			&:focus {
				color: var(--background-color);
			}

			&:hover {
				color: var(--background-color);
			}
		}
	}

	&--powered-by {
		display: none;
		&__link {
			display: none;
		}
	}
}

.welcome h1,
.welcome h2 {
	color: white !important;
}

.uploadcare--file_status_uploading .uploadcare--file__preview {
	color: var(--mint-primary) !important;
	border-color: transparent !important;
}

@media (min-width: 660px) and (max-width: 1024px) {
	.uploadcare{
		&--button {
			&_muted {
				//display: unset !important;
			}
		}
	}
}

@media only screen and (min-width: 1025px) {
	.uploadcare {
		&--button {
			&_muted {
				//display: unset !important;
			}
		}
	}
}
